import React from "react"

import Basel from "./basel";


const IndexPage = () => {
  return (
    <Basel />)
}

export default IndexPage
